@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS yang Document 2018.05
Last Update 2018.05 yang
---------------------------------*/
/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  // #SearchWrap {
  //   .searchBtn {
  //     display: block;
  //     width: 100%;
  //     background: #fae6d8 url(../img/contents/icon_01.png) no-repeat left 15px center;
  //     background-size: 17px auto;
  //     padding: 10px 15px 10px 36px;
  //     text-align: left;
  //     text-decoration: none;
  //     .search {
  //       display: block;
  //       width: 100%;
  //       height: 100%;
  //       font-size: 18px;
  //       color: #f98b3c;
  //       font-weight: 700;
  //       letter-spacing: .075em;
  //       line-height: 1.5;
  //       padding-right: 21px;
  //       background: url(../img/contents/arrow_og.png) no-repeat right center;
  //       background-size: 8px auto;
  //     }
  //   }
  // }
  #MainImg {
    .image {
      width: 100%;
      height: 50vw;
      background: url(../img/contents/top/mainimg.jpg) no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  #Main {
    .link {
      &_cont {
        a {
          text-decoration: none;
          background-position: right 15px center;
          background-repeat: no-repeat;
          background-size: 8px auto;
        }
      }
      &_list {
        display: flex;
        flex-wrap: wrap;
        &_item {
          width: 50%;
          a {
            display: block;
            font-size: 16px;
            color: #333;
            background-color: #f4f9ff;
            background-image: url(../img/contents/arrow_gr.png);
            padding: 13px 30px 13px 16px;
            margin-bottom: 2px;
          }
          &:nth-of-type(2n) { border-left: 1px solid #fff; }
        }
      }
      &_button {
        display: flex;
        &_item {
          width: 50%;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          letter-spacing: 0;
          line-height: 26px;
          background-image: url(../img/contents/arrow_wh_next.png);
          padding: 12px 30px 10px 15px;
          small { font-size: 14px; }
          &.left {
            background-color: #62c98d;
          }
          &.right {
            display: flex;
            align-items: center;
            background-color: #ff8416;
            background-image: url(../img/contents/icon_01_wh.png), url(../img/contents/arrow_wh_next.png);
            background-position: left 15px center, right 15px center;
            background-size: 17px auto, 8px auto;
            padding-left: 37px;
          }
        }
      }
    }
    #ContBox01 {
      .contSubBox {
        padding: 0 30px;
        h2 {
          margin-top: 2px;
          padding: 32px 0 30px;
          position: relative;
          display: block;
          font-size: 26px;
          color: #0f437d;
          text-align: center;
          &:after {
            position: absolute;
            content: '';
            width: 30px;
            height: 2px;
            background-color: #8cafd6;
            left: 50%;
            bottom: 18px;
            transform: translateX(-50%);
          }
        }
      }
    }
    #ContBox02 {
      margin-top: 45px;
      .contSubBox {
        padding: 0;
        .footerMenu {
          width: 100%;
          background-color: #f4f9ff;
          .menuBox {
            border-bottom: 1px solid #fff;
            &:last-of-type { border-bottom-width: 0; }
          }
          .menu {
            display: block;
            background: url(../img/contents/arrow_gr.png) no-repeat center right 15px;
            background-size: 8px auto;
            font-size: 16px;
            color: #333;
            text-decoration: none;
            padding: 15px;
            &.green {
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              background-color: #62c98d;
              background-image: url(../img/contents/arrow_wh_next.png);
              small { font-size: 14px; }
            }
            &.orange {
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              background-color: #ff8416;
              background-image: url(../img/contents/icon_01_wh.png), url(../img/contents/arrow_wh_next.png);
              background-position: left 15px center, right 15px center;
              background-size: 17px auto, 8px auto;
              padding-left: 37px;
            }
          }
        }
      }
    }
  }
}

/*
当会について
-------------------------------------*/
#PageAbout.pageIndex {
  background-color: #f4f4f4;
  #Main {
    #ContBox01 {
      padding: 20px 0;
      .contSubBox02 {
        .content {
          padding-top: 40px;
          .soshiki {
            display: block;
            width: 100%;
            max-width: 305px;
            margin: 0 auto;
          }
        }
      }
      .contSubBox04 {
        .content {
          dl {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            dt { width: 200px; }
          }
          .note {
            display: block;
            font-size: 14px;
            color: #888;
            margin-top: 19px;
          }
        }
      }
      .contSubBox05 {
        .content {
          table.samugawa { margin-top: 0; }
        }
      }
      .contSubBox06 {
        .content {
          .textList {
            .text {
              margin-top: 10px;
              &:first-of-type { margin-top: 0; }
            }
          }
        }
      }
    }
  }
}

/*
活動内容
-------------------------------------*/
#PageActivity.pageIndex {
  background-color: #f4f4f4;
  #Main {
    #ContBox01 {
      padding: 20px 0;
      .contSubBox {
        border-bottom: 1px solid #f4f4f4;
        .textList {
          .text {
            position: relative;
            line-height: 1.8;
            text-align: justify;
            &:before {
              position: absolute;
              left: 0;
            }
          }
        }
      }
      .contSubBox01 {
        .showBoxCont {
          .textList {
            margin-top: 18px;
            .text {
              padding-left: 83px;
              &:before {
                content: '営業時間：';
              }
            }
          }
        }
      }
      .contSubBox03 {
        .showBoxCont {
          .textList {
            margin-top: 18px;
            .text {
              padding-left: 75px;
              padding-top: 23px;
              margin-top: 8px;
              &:before {
                top: 0;
                font-size: 16px;
                font-weight: bold;
                line-height: 26px;
                white-space: pre;
              }
              &:nth-of-type(1) {
                margin-top: 0;
                &:before { content: '令和\A元年度 ：'; }
              }
              &:nth-of-type(2):before { content: '平成\A３０年度：'; }
              &:nth-of-type(3):before { content: '平成\A２９年度：'; }
              &:nth-of-type(4):before { content: '平成\A２８年度：'; }
              &:nth-of-type(5):before { content: '平成\A２７年度：'; }
              &:nth-of-type(6):before { content: '平成\A２６年度：'; }
            }
          }
        }
      }
      .contSubBox08 {
        .dotList {
          margin-top: 10px;
          .dot:nth-of-type(2) { margin-top: 10px; }
        }
      }
      .contSubBox09 {
        .dotList {
          margin-top: 10px;
          .dot {
            margin-top: 10px;
            &:first-of-type { margin-top: 0; }
          }
        }
      }
    }
  }
}

/*
入会案内
-------------------------------------*/
#PageInvitation.pageIndex {
  background-color: #f4f4f4;
  #Main {
    #ContBox01 {
      padding: 20px 0;
      .contSubBox {
        border-bottom: 1px solid #f4f4f4;
        .click { padding-right: 60px; }
        .showBoxCont {
          .subTitle {
            color: #1557a1;
            font-weight: 600;
            line-height: 1.8;
            margin-top: 18px;
          }
          .numList {
            margin-top: 18px;
            .num { margin-top: 18px; }
          }
          .textList {
            .text {
              line-height: 1.8;
              margin-top: 10px;
              &:first-of-type { margin-top: 0; }
            }
          }
        }
      }
    }
  }
}

/*
お知らせカテゴリ
-------------------------------------*/
#PageNews.pageCategory {
  #Main {
    #ContBox01 {
      padding-bottom: 60px;
      .contSubBox {
        padding: 0 30px;
      }
      .postListIn {
        .post {
          &:first-of-type a { padding-top: 0; }
        }
      }
    }
    .btnWrap {
        margin-top: -16px;
    }
  }
  #InfoWrap { border-top: 1px solid #f4f4f4; }
}

/*
お知らせ記事詳細
-------------------------------------*/
#PageNews.pageEntry {
  #TopicPath {
    li:last-of-type {
      max-width: calc(100% - 106.87px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  #Main {
    #ContBox01 {
      padding-bottom: 70px;
      .contSubBox {
        padding: 0 30px;
        .titleBox {
          padding-bottom: 25px;
          border-bottom: 1px solid #f4f4f4;
          .time {
            display: block;
            font-size: 15px;
            color: #7292b6;
            line-height: 1;
            letter-spacing: .1em;
          }
          .title {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.6;
            margin-top: 7px;
          }
        }
        .entryBox {
          line-height: 1.6;
          padding: 23px 0;
          p {
            &:last-of-type { margin-bottom: 0 !important; }
          }
        }
      }
      .buttonWrap {
        .buttonIn {
          width: 315px;
          margin: 0 auto;
          padding:  0 41px 0;
          text-align: center;
          .btnBox {
            display: inline-block;
            width: 102px;
            height: 35px;
            margin-top: 25px;
            margin-right: 22px;
            &:last-of-type { margin-right: 0;}
          }
        }
        .button {
          width: 100%;
          height: 100%;
          .btnText {
            font-size: 14px;
            top: 8%;
            transform: translateY(-8%);
          }
          &.prev {
            .btnText {
              padding-left: 16px;
              background-image: url(../img/contents/arrow_wh_prev.png);
              background-size: 6px auto;
              background-position: left top 4px;
            }
          }
          &.next {
            .btnText {
              padding-right: 16px;
              background-image: url(../img/contents/arrow_wh_next.png);
              background-size: 6px auto;
              background-position: right top 4px;
            }
          }
        }
      }
    }
    .btnWrap {
        margin-top: -26px;
    }
  }
  #InfoWrap { border-top: 1px solid #f4f4f4; }
}

/*
会員専用
-------------------------------------*/
#PageMember.pageIndex {
  // #MainImg {
  //   .image {
  //     width: 100%;
  //     height: 50vw;
  //     background: url(../img/contents/member/mainimg.jpg) no-repeat;
  //     background-position: center;
  //     background-size: cover;
  //   }
  //   .linkIn {
  //     width: 100%;
  //     overflow: auto;
  //     .link {
  //       width: 50%;
  //       float: left;
  //       background-color: #62c98d;
  //       position: relative;
  //       &:first-of-type:after {
  //         position: absolute;
  //         content: '';
  //         width: 1px;
  //         height: 100%;
  //         background-color: #fff;
  //         top: 0;
  //         right: -1px;
  //         z-index: 1;
  //       }
  //     }
  //     .linkCont {
  //       display: block;
  //       width: 100%;
  //       background: url(../img/contents/arrow_wh_next.png) no-repeat center right 15px;
  //       background-size: 7px auto;
  //       font-size: 14px;
  //       color: #fff;
  //       text-align: left;
  //       text-decoration: none;
  //       letter-spacing: .025em;
  //       padding: 11px 15px 8px;
  //     }
  //   }
  //   .memberMainImg { margin-top: 11px; }
  // }
  #Main {
    padding-bottom: 60px;
    border-bottom: 1px solid #f4f4f4;
    .link {
      &_cont {
        display: flex;
        flex-wrap: wrap;
        & > .link_item:nth-of-type(6),
        & > .link_item:nth-last-of-type(3) { border-left-width: 0; }
      & > .link_item:nth-last-of-type(2) { border-left: 1px solid #fff; }
      }
      &_item {
        width: 50%;
        margin-bottom: 1px;
        a {
          display: flex;
          align-items: center;
          height: 100%;
          font-size: 16px;
          color: #333;
          line-height: 21px;
          text-decoration: none;
          background: #f4f9ff url(../img/contents/arrow_gr.png) no-repeat right 15px center;
          background-size: 8px auto;
          padding: 15px 30px 14px 16px;
        }
        span {
          display: block;
          background-color: #f4f9ff;
          padding: 14px 15px 13px;
          font-size: 16px;
          letter-spacing: 0.05em;
        }
        &:nth-of-type(2n) { border-left: 1px solid #fff; }
        &.w-100 { width: 100%; }
        &.orange {
          a {
            color: #fff;
            background-color: #ff8416;
            background-image: url(../img/contents/arrow_wh_02.png);
            background-size: auto 8px;
          }
        }
        &.green {
          a {
            color: #fff;
            background-color: #62c98d;
          }
        }
        &:last-of-type {
          border-left-width: 0;
          a { background-image: url(../img/contents/arrow_wh_next.png); }
        }
      }
      &_sub {
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px 18px;
        background-color: #f4f9ff;
        .link_item {
          a {
            background-color: #e2eefb;
            background-image: url(../img/contents/icon_04.png), url(../img/contents/arrow_gr.png);
            background-position: left 15px center, right 15px center;
            padding-left: 30px;
          }
        }
      }
    }
    .contBox {
      h2 {
        margin-top: 2px;
        padding: 32px 0 30px;
        position: relative;
        display: block;
        font-size: 26px;
        color: #0f437d;
        text-align: center;
        &:after {
          position: absolute;
          content: '';
          width: 30px;
          height: 2px;
          background-color: #8cafd6;
          left: 50%;
          bottom: 18px;
          transform: translateX(-50%);
        }
      }
    }
    .contSubBox { padding: 0 30px; }
    #ContBox01 {
      padding-bottom: 19px;
      .contSubBox {
        margin-top: 3px;
      }
    }
    #ContBox02 {
      .post a {
        display: flex;
        align-items: start;
        .thumb {
          width: 77.5px;
          height: 77.5px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .cont {
          width: calc( 100% - 77.5px );
          padding-left: 15px;
        }
      }
    }
  }
}

/*
会員専用　記事詳細
-------------------------------------*/
#PageMember.pageEntry {
  #TopicPath {
    li:last-of-type {
      max-width: calc(100% - 36.48px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  #MainImg {
    h2.yuMin { padding: 0 15px; }
  }
  #Main {
    #ContBox01 {
      padding-bottom: 60px;
      .contSubBox {
        table.memberTable {
          tr:last-of-type {
            td { border-bottom-width: 1px !important; }
          }
          th {
            display: block;
            padding: 11px 15px !important;
            border-bottom-width: 0 !important;
          }
          td {
            display: block;
            border-top-width: 0 !important;
            p.title {
              margin-bottom: 7px !important;
              font-size: 18px !important;
              font-weight: bold;
              line-height: 1.6;
            }
            p.teacher {
              margin: 0 !important;
              color: #888 !important;
              padding-left: 49px;
              position: relative;
              &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '講師';
                background-color: #80a1c7;
                border-radius: 4px;
                padding: 4px 4px 3px 5px;
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                line-height: 1;
                text-align: center;
              }
            }
            &:last-of-type { border-bottom-width: 0 !important; }
          }
        }
        .entryBox {
          p.mg-10 { margin-top: 10px; }
        }
        .btnWrap {
          margin-top: 44px;
          margin-bottom: 0;
        }
      }
    }
  }
  #InfoWrap { border-top: 1px solid #f4f4f4; }
}

/*
地域医療センター薬局
-------------------------------------*/
#PageCenter.pageIndex {
  background-color: #f4f4f4;
  #MainImg {
    h2 { padding: 0 40px; }
  }
  #Main {
    #ContBox01 {
      padding: 20px 0;
    }
  }
}


@media screen and (max-width: 320.99px) {
  /*
  会員専用
  -------------------------------------*/
  #PageMember.pageIndex {
    #MainImg .linkWrap {
      ul.linkIn {
        min-height: 100%;
        overflow: hidden;
        li.link {
          padding-bottom: 100px;
          margin-bottom: -100px;
          &:first-of-type .linkCont { padding-right: 30px; }
          &:last-of-type .linkCont { transform: translateY(22%); }
        }
      }
    }
  }
}

@media screen\0 {
  #PageNews.pageEntry #Main {
    #ContBox01 {
      .buttonWrap .button { line-height: 35px; }
    }
  }
  #PageMember.pageEntry #Main {
    #ContBox01 .contSubBox {
      a.memberTopBtn { line-height: 35px; }
    }
  }
}